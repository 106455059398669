import { BaseEntity } from './BaseEntity';
import { Plan } from './Plan';
import { Subscription } from './Subscription';

export enum UserStatus {
  ENABLE = 'enable',
  DISABLE = 'disable'
}

export interface User extends BaseEntity{
  avatar: string
  username: string
  firstName: string
  lastName: string
  email: string
  emailVerifiedAt: string
  birthdate: string
  status: UserStatus
  plan: Plan
  subscription: Subscription
}

export type PartialUser = Partial<User>;
